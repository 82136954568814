import   React            from "react"
import { useLocation }    from "@reach/router"

import { graphql,
         useStaticQuery,
         Link }           from "gatsby"

import { normalize } from "../../hooks/normalize"
import { date      } from "../../hooks/upcc-date"


export default () => {
  const home = (useLocation().pathname == '/') ? true : false

  let { allContentful2023Programa: { nodes } } = useStaticQuery(
    graphql`
    {
      allContentful2023Programa(filter: {node_locale: {eq: "en-US"}}) {
        nodes {
          name
          description
          image { file { url } }
          featured
        }
      }
    }
  `);
  console.log('wall programs: ', { nodes })

  nodes = nodes.filter(node => node.featured)

  return (
    <>
      <section class="wall dark">
        <h1 class="upcc-h1 white center">Programas Destacados</h1>
        <h3 class="upcc-h3 white center">Contenido original</h3>

        <div class="wall-flex-container">
        <div class="wall-flex">
          {
            nodes.map(( node, i) =>
              <article>
                <Link to={ `/programas/${ normalize(node.name) }` }>
                  <img class="upcc-img" src={ node.image.file.url } />
                  <span class="upcc-tag red">PROGRAMAS</span>
                  <h5 class="upcc-h5 white">{ node.name }</h5>
                  <p class="upcc-small white">{ node.description }</p>
                </Link>
              </article>
            )
          }
        </div>
        </div>

        {home
          ? <Link className="more white center" to={`/programas`}>VER MÁS<br/><i class="fa fa-angle-down"></i></Link>
          : <i/>
          // : <a class="more white center" href="#more">VER MÁS<br/><i class="fa fa-angle-down" id="more"></i></a>
        }
      </section>
    </>
  )
}