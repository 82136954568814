import   React            from "react"
import { useLocation }    from "@reach/router"

import { graphql,
         useStaticQuery,
         Link }           from "gatsby"

import { normalize } from "../../hooks/normalize"
import { date      } from "../../hooks/upcc-date"


export default () => {
  const home = (useLocation().pathname == '/') ? true : false

  let { allContentfulArteYCultura: { nodes: posts }, allContentful2023Evento: { nodes: posts2 } } = useStaticQuery(
    graphql`
      query {
        allContentfulArteYCultura(limit: 2, filter: {node_locale: {eq: "en-US"}, categorylink: {in: "8"}, activarPublicacion: {eq: true}}, sort: {fields: fechaDeOrden, order: DESC}) {
          nodes {
            titulo,
            tituloDeRespaldo
            descripcion
            fecha
            fechaDeOrden
            imagenDeLaEntrada { file { url } }
            descripcionEnElBlog
          }
        },
        allContentful2023Evento(filter: {node_locale: {eq: "en-US"}, featured: {eq: true}}) {
          nodes {
            title
            description
            image { file { url } }
            date
          }
        }
      }
    `);

  posts.forEach(e => {
    e.path = 'arteycultura'
  })

  posts2.forEach(e => {
    e.titulo                 = e.title
    e.tituloDeRespaldo       = e.title
    e.descripcionEnElBlog    = e.description
    e.imagenDeLaEntrada      = e.image
    e.fecha                  = e.date
    e.path                   = 'agenda'
  })

  // posts = posts2.concat(posts)
  posts = posts2

  return (
    <>
      <section class="wall">
        <h1 class="upcc-h1 white center">Agenda Cultural</h1>
        <h3 class="upcc-h3 white center">Eventos destacados</h3>

        <div class="wall-flex">
          {
            posts.map(( post, i) => {
              return (
                <article>
                  <Link to={`/${post.path}/${normalize(post.titulo)}`}>
                    <figure>
                      <img class="upcc-img" src={ post.imagenDeLaEntrada.file.url } />
                      <span class="date">{ date(post.fecha).dd }<small>{ date(post.fecha).mm }</small></span>
                    </figure>
                    <span class="upcc-tag purple">EVENTOS</span>
                    <h5 class="upcc-h5 white">{ post.tituloDeRespaldo }</h5>
                    <p class="upcc-small white">{ post.descripcionEnElBlog }</p>
                  </Link>
                </article>
              )
            })
          }
        </div>

        {home
          ? <Link className="more white center" to={`/agenda`}>VER MÁS<br/><i class="fa fa-angle-down"></i></Link>
          : <a class="more white center" href="#more">VER MÁS<br/><i class="fa fa-angle-down" id="more"></i></a>
        }
      </section>
    </>
  )
}
